import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Controller, Scene } from 'react-scrollmagic'

import { Image } from '../styles/GalleryLayout'
import { ParagraphS } from '../styles/Typography'

/**
 * Grid item with image and title.
 * @param {ComponentProps} props
 * @param {Object} props.item
 * @param {number} props.index
 * @returns {StatelessComponent}
 */
const Item = ({ item, index }) => (
  <Controller>
    <Scene
      classToggle="reveal"
      triggerElement={`#item-reveal-${index}`}
      reverse={false}
    >
      <div className="post">
        <Link
          to={`/${item.slug.current}`}
          onClick={() => {
            document.getElementById('fb-root').classList.add('hide')
          }}
        >
          <Image>
            <Img fluid={item.images[0].asset.fluid} alt={item.name} />
            <Img fluid={item.images[1].asset.fluid} alt={item.name} />
          </Image>
        </Link>
        <div className="info">
          <ParagraphS>{item.name}</ParagraphS>
          <Link
            to={`/${item.slug.current}`}
            className="read"
            onClick={() => {
              document.getElementById('fb-root').classList.add('hide')
            }}
          >
            <span className="text">Read More</span>
            <span className="line">+</span>
          </Link>
        </div>
      </div>
    </Scene>
  </Controller>
)

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.array,
    images: PropTypes.array,
    name: PropTypes.string,
    slug: PropTypes.shape({
      current: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
}

export default Item
