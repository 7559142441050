import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const GalleryLayout = styled.div`
  width: 100%;
  padding: ${({ isHomePage }) => (isHomePage ? '15% 9vw 5%' : '6% 9vw 5%')};

  @media (max-width: ${breakpoints.screenLG}) {
    padding: ${({ isHomePage }) => (isHomePage ? '8% 9vw' : '6% 9vw 8%')};
  }

  .wrapper {
    display: grid;
    row-gap: 16vh;
    column-gap: 8vw;
    grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));

    @media (max-width: ${breakpoints.screenLG}) {
      row-gap: 6vh;
      column-gap: 4vw;
      grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    }

    .item-reveal {
      :nth-child(3n + 1) .post {
        transition-delay: 0s;
      }

      :nth-child(3n - 1) .post {
        transition-delay: 0.1s;
      }

      :nth-child(3n) .post {
        transition-delay: 0.2s;
      }

      @media (max-width: ${breakpoints.screenLG}) {
        :nth-child(3n + 1) .post,
        :nth-child(3n - 1) .post,
        :nth-child(3n) .post {
          transition-delay: 0s;
        }

        :nth-child(2n) .post {
          transition-delay: 0.2s;
        }
      }
    }
  }

  .post {
    display: grid;
    gap: 4%;
    align-content: start;
    color: ${({ theme }) => theme.textPrimary};
    width: 100%;
    opacity: 0;
    transform: translateX(-4vw);
    transition: opacity 1s ease-out,
      transform 1.4s cubic-bezier(0.33, 1, 0.68, 1);

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    max-width: 85%;
  }

  .read {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.9vw;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-top: 5%;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
    }

    .line {
      font-size: 1.1vw;
      font-weight: 500;
      opacity: 1;
      padding-left: 0.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 2.6vw;
        padding-left: 1vw;
      }
    }
  }

  .pagination {
    display: flex;
    margin-top: 10%;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-top: 14%;
    }
  }

  .gallery-link {
    font-size: 1.1vw;
    font-weight: 400;
    letter-spacing: 0.08em;
    margin-left: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 2.2vw;
    }

    svg {
      margin-right: 1.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-right: 3vw;
        width: 38px;
        height: auto;
      }
    }
  }

  .controls {
    font-size: 1.1vw;
    font-weight: 400;
    letter-spacing: 0.08em;
    margin-left: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 2.2vw;
    }

    a {
      padding: 0 0.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        padding: 0 1vw;
      }

      &[aria-current],
      &.current {
        font-weight: 700;
      }

      &[disabled] {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .arrow-left {
      margin-right: 1vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-right: 2vw;

        svg {
          width: 38px;
          height: auto;
        }
      }
    }

    .arrow-right {
      margin-left: 1vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-left: 2vw;

        svg {
          width: 38px;
          height: auto;
        }
      }
    }
  }
`

const Image = styled.div`
  position: relative;
  overflow: hidden;
  height: 28vw;

  @media (max-width: ${breakpoints.screenLG}) {
    height: 50vw;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;

    &:nth-child(1) {
      opacity: 1;
    }

    &:nth-child(2) {
      opacity: 0;
    }
  }

  &:hover {
    .gatsby-image-wrapper:nth-child(1) {
      opacity: 0;
    }

    .gatsby-image-wrapper:nth-child(2) {
      opacity: 1;
    }
  }

  img {
    height: 100%;
    object-position: 50% 35% !important;
  }
`

export { GalleryLayout, Image }
