import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Gallery from '../components/Gallery'
import { Container } from '../styles/PageLayout'

export default function GalleryPage({ data, pageContext }) {
  const items = data.items.nodes
  const totalCount = data.items.totalCount

  return (
    <>
      <SEO title="Gallery" />
      <Container column mobileColumn>
        <Gallery
          items={items}
          pageContext={pageContext}
          totalCount={totalCount}
        />
      </Container>
    </>
  )
}

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 12) {
    items: allSanityItem(
      sort: { fields: _createdAt, order: DESC }
      limit: $pageSize
      skip: $skip
    ) {
      totalCount
      nodes {
        id
        name
        description {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          style
        }
        slug {
          current
        }
        images {
          asset {
            fluid(maxWidth: 350) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
