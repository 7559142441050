import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import ArrowLeft from './icons/ArrowLeft'
import ArrowRight from './icons/ArrowRight'

/**
 * Pagination of the image grid.
 * @param {ComponentProps} props
 * @param {number} props.pageSize
 * @param {number} props.totalCount
 * @param {number} props.currentPage
 * @param {string} props.base
 * @returns {StatelessComponent}
 */
const Pagination = ({ pageSize, totalCount, currentPage, base }) => {
  const totalPages = Math.ceil(totalCount / pageSize)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1

  return (
    <div className="controls">
      <Link
        to={`${base}/${prevPage}`}
        disabled={!hasPrevPage}
        className="arrow-left"
      >
        <ArrowLeft />
      </Link>

      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          to={`${base}/${i > 0 ? i + 1 : ''}`}
          key={`page${i}`}
          className={currentPage === 1 && i === 0 ? 'current' : ''}
        >
          {i + 1}
        </Link>
      ))}

      <Link
        to={`${base}/${nextPage}`}
        disabled={!hasNextPage}
        className="arrow-right"
      >
        <ArrowRight />
      </Link>
    </div>
  )
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
}

export default Pagination
