import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { GalleryLayout } from '../styles/GalleryLayout'
import { Container } from '../styles/PageLayout'

import Item from './Item'
import ArrowExtended from './icons/ArrowExtended'
import Pagination from './Pagination'

/**
 * Image grid to be used in home and galery pages.
 * @param {ComponentProps} props
 * @param {Array} props.items
 * @param {Object} props.pageContext
 * @param {number} props.totalCount
 * @param {boolean} props.isHomePage
 * @returns {StatelessComponent}
 */
const Gallery = ({ items, pageContext, totalCount, isHomePage }) => (
  <Container justifyContent="flex-start">
    <GalleryLayout isHomePage={isHomePage}>
      <div className="wrapper">
        {items.map((item, index) => (
          <div
            className="item-reveal"
            key={item.id}
            id={`item-reveal-${index}`}
          >
            <Item item={item} index={index} />
          </div>
        ))}
      </div>
      <div className="pagination">
        {isHomePage ? (
          <Link to="/gallery" className="gallery-link">
            <ArrowExtended />
            See the full gallery
          </Link>
        ) : (
          <Pagination
            pageSize={12}
            totalCount={totalCount}
            currentPage={pageContext.currentPage || 1}
            base="/gallery"
          />
        )}
      </div>
    </GalleryLayout>
  </Container>
)

Gallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.array,
      images: PropTypes.array,
      name: PropTypes.string,
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
    })
  ).isRequired,
  pageContext: PropTypes.shape({
    currentItem: PropTypes.number,
    nextSlug: PropTypes.string,
    previousSlug: PropTypes.string,
    slug: PropTypes.string,
    totalItems: PropTypes.number,
  }),
  totalCount: PropTypes.number,
  isHomePage: PropTypes.bool,
}

export default Gallery
