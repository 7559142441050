import React from 'react'

import theme from '../../constants/theme'

const ArrowExtended = ({ color = theme.textPrimary }) => (
  <svg width="24" height="11" viewBox="0 0 24 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 7H0L0 7.5H0.5L0.5 7ZM23.3536 7.35355C23.5488 7.15829 23.5488 6.84171 23.3536 6.64645L20.1716 3.46447C19.9763 3.2692 19.6597 3.2692 19.4645 3.46447C19.2692 3.65973 19.2692 3.97631 19.4645 4.17157L22.2929 7L19.4645 9.82843C19.2692 10.0237 19.2692 10.3403 19.4645 10.5355C19.6597 10.7308 19.9763 10.7308 20.1716 10.5355L23.3536 7.35355ZM0 0.5L0 7H1L1 0.5L0 0.5ZM0.5 7.5L23 7.5V6.5L0.5 6.5L0.5 7.5Z"
      fill={color}
    />
  </svg>
)

export default ArrowExtended
